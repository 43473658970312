import React from 'react';
import { JsonRpcProvider, Web3Provider } from '@ethersproject/providers';
import { AddressZero } from '@ethersproject/constants';
import { useWeb3React } from '@web3-react/core';
import { buildContextHook } from 'src/hooks/utils';

declare type ChainID = number;
declare type ContractLabel =
  // Marketplace
  | 'NFT_ADDRESS'
  | 'MARKETPLACE_ADDRESS'
  | 'MARKETPLACE_AGGREGATOR'
  | 'AUCTION_ADDRESS'
  | 'FACTORY_ADDRESS'
  | 'PRIVATE_FACTORY_ADDRESS'
  | 'ART_FACTORY_ADDRESS'
  | 'PRIVATE_ART_FACTORY_ADDRESS'
  // DRM
  | 'DIGITAL_ASSET_LEDGER'
  | 'AUTHORITY_GATEWAY'
  | 'FACTORY_OPERATIVE_BUY'
  | 'FACTORY_OPERATIVE_BUY_SELL'
  | 'CORE_STORAGE';
declare type CurrenciesInUse = 'ELA' | 'WELA' | 'USDC' | 'WETH';

interface IEcosystem<C extends string, S extends string> {
  rpcs: Record<ChainID, string>;
  contracts: Record<ChainID, Partial<Record<C, string>>>;
  currencies: Record<ChainID, Partial<Record<S, string>>>;
}

export const ecosystem: IEcosystem<ContractLabel, CurrenciesInUse> = {
  rpcs: {
    1337: 'http://localhost:8545',
    21: 'https://api2-testnet.elastos.net/eth',
    // 21: 'https://api-testnet.trinity-tech.io/esc',
    20: 'https://api2.elastos.net/esc',
  },
  contracts: {
    1337: {
      CORE_STORAGE: '0xCBAc4fd5Dff4C402c0E077f8C345bf1Af5B3b3Db',
      AUTHORITY_GATEWAY: '0x47275CbEd27FfA0377830885689d2eB24F11926D',
      DIGITAL_ASSET_LEDGER: '0xfD0d52bf02FE6e265D6fC65Bb68b64bb3c71Cd83',
    },
    21: {
      NFT_ADDRESS: '0xF00E6e2D8b3c9D16F23f72DA45A22cfC27d3C3c9',
      MARKETPLACE_ADDRESS: '0xcC10bbE763C93Da6fe4D0BC336eaB7f538015eA3',
      MARKETPLACE_AGGREGATOR: '0x1680AA103f60712Eb5a6f42B4407c37da85553E7',
      AUCTION_ADDRESS: '0x29AC830C82103ce07a73c1fc8207617043F79d3A',
      FACTORY_ADDRESS: '0x1133F93Fa072010483288Af2daDe47438348Da59',
      PRIVATE_FACTORY_ADDRESS: '0x6E20188585522BB63B18062Cbc819BC571Fff2b7',
      ART_FACTORY_ADDRESS: '0xF62e2e26846Edb90604485bd9b64F4569E9F2C49',
      PRIVATE_ART_FACTORY_ADDRESS: '0x5B2194735Fd8453468a49A108c8aebCD31ae7244',

      CORE_STORAGE: '0x98929adc850C4105F2008E8a32bE92c8FFC86927',
      AUTHORITY_GATEWAY: '0x223167e2Dba1829EaE1dA8545B9EFc1F4a3609c7',
      DIGITAL_ASSET_LEDGER: '0x2c30a0c312aaf21f0AD912140d7bD95BF89bbD88',
    },
    20: {
      NFT_ADDRESS: '0x23F6082A2b4C00D31C95C5b3056deFaF79926015',
      MARKETPLACE_ADDRESS: '0x0Dec73b5C65f6aaC8F95Aa908291627e490c1eF8',
      MARKETPLACE_AGGREGATOR: '0xf30391f86C28895F8d2251DFe72930aB9C4ff66A',
      AUCTION_ADDRESS: '0x4E3679FE16E91507e5C75E8ff8e55CaA03407430',
      FACTORY_ADDRESS: '0x2A0FF04f22cCe31e5913Acd077253222059d0b30',
      PRIVATE_FACTORY_ADDRESS: '0x9fe24e8aa360e090cf766e7e9905d7637df92215',
      ART_FACTORY_ADDRESS: '0x80857bc54Af34A28F372E1e066e5976767eac0d2',
      PRIVATE_ART_FACTORY_ADDRESS: '0x3EA5313AC1B46465639374d5241a1bdfD248e9dd',

      CORE_STORAGE: '0x71DB9d06178B433ad2330A2030F8af1E8EC5197b',
      AUTHORITY_GATEWAY: '0xA98FC82c9772b583fd079262390885a86EFe80Be',
      DIGITAL_ASSET_LEDGER: '0x9057304A41919008d79B3Bb3fCEBd69414e38b1F',
    },
  },
  currencies: {
    1337: {
      ELA: AddressZero,
    },
    21: {
      ELA: AddressZero,
      WELA: '0x517e9e5d46c1ea8ab6f78677d6114ef47f71f6c4',
      USDC: '0x175F5f4990D0Ee1B43Fa250357C5AFE7a9138908',
    },
    20: {
      ELA: AddressZero,
      WELA: '0x517e9e5d46c1ea8ab6f78677d6114ef47f71f6c4',
      USDC: '0xA06be0F5950781cE28D965E5EFc6996e88a8C141',
    },
  },
};

interface EcosystemContextValue {
  chainId?: ChainID;
  contracts: Partial<Record<ContractLabel, string>>;
  provider?: JsonRpcProvider | Web3Provider;
}

export const EcosystemContext = React.createContext<EcosystemContextValue>({
  contracts: {},
});

interface EcosystemProviderProps {
  defaultChainId: ChainID;
}

export const EcosystemProvider = ({ children, defaultChainId }: React.PropsWithChildren<EcosystemProviderProps>) => {
  const { chainId: connectedChainId, library } = useWeb3React();

  const chainId: ChainID = React.useMemo(() => (connectedChainId as ChainID) || defaultChainId, [connectedChainId]);

  const contracts = React.useMemo(() => {
    if (!connectedChainId || !ecosystem.contracts[connectedChainId]) {
      // return default
      return ecosystem.contracts[defaultChainId] || {};
    }

    return ecosystem.contracts[connectedChainId];
  }, [connectedChainId]);

  const provider = React.useMemo(() => {
    if (library) {
      return library;
    }

    return new JsonRpcProvider(ecosystem.rpcs[defaultChainId], defaultChainId);
  }, [connectedChainId, library]);

  return (
    <EcosystemContext.Provider
      value={{
        chainId,
        contracts,
        provider,
      }}
    >
      {children}
    </EcosystemContext.Provider>
  );
};

export const useEcosystem = buildContextHook(EcosystemContext, 'useEcosystem', 'EcosystemProvider');
