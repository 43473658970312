import React from 'react';
import PlayerCore from '@elacity-js/media-player';
import { Web3Provider } from '@ethersproject/providers';
import { useEcosystem } from 'src/lib/web3';

declare global {
  interface Window {
    ManagedMediaSource: MediaSource; // Replace 'any' with the correct type if available
  }
}

interface MediaPlayerState {
  moduleLoaded: boolean;
  loading?: boolean;
  playerSupported?: boolean;
}

interface MediaPlayerContextType extends MediaPlayerState {

}

export const MediaPlayerContext = React.createContext<MediaPlayerContextType>({
  moduleLoaded: false,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state: MediaPlayerContextType, action: { type: string, payload: any }) => {
  switch (action.type) {
    case 'moduleLoaded':
      return { ...state, moduleLoaded: action.payload };
    case 'loading':
      return { ...state, loading: action.payload };
    case 'playerSupported':
      return { ...state, playerSupported: action.payload };
    default:
      return state;
  }
};

interface MediaPlayerProviderProps {
  remote?: boolean;
  websocketURL?: string;
}

const ElacityMediaPlayerProvider = ({
  children,
  remote,
  websocketURL,
}: React.PropsWithChildren<MediaPlayerProviderProps>) => {
  const [playerState, dispatch] = React.useReducer(reducer, {
    moduleLoaded: false,
    loading: false,
    playerSupported: Boolean(window.crossOriginIsolated) && (Boolean(window.MediaSource) || Boolean(window.ManagedMediaSource)),
  });

  const { provider: lib } = useEcosystem();

  React.useEffect(() => {
    if (!playerState.loading && !playerState.moduleLoaded) {
      dispatch({
        type: 'loading',
        payload: true,
      });
      PlayerCore.setup({
        remote: Boolean(remote),
        ...(websocketURL && {
          // @since 2.0-rc3, websocket is no more in use, prefer usage of memfs
        }),
      }).then(() => {
        dispatch({
          type: 'moduleLoaded',
          payload: true,
        });
      }).catch(console.error).finally(() => {
        dispatch({
          type: 'loading',
          payload: false,
        });
      });
    }
  }, [playerState.loading, playerState.moduleLoaded]);

  React.useEffect(() => {
    if (playerState.moduleLoaded && lib) {
      PlayerCore.setProvider((lib as Web3Provider).provider);
    }
  }, [playerState.moduleLoaded, lib]);

  return (
    <MediaPlayerContext.Provider value={{
      ...playerState,
    }}
    >
      {children}
    </MediaPlayerContext.Provider>
  );
};

export const usePlayerContext = () => React.useContext(MediaPlayerContext);
export default ElacityMediaPlayerProvider;
